import React from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import AndroidImage from './android.svg'
import IosImage from './ios.svg'
import eventConstants from '../../global/eventConstants'


const BadgeBase = styled.a`
  display: inline-block;

  ${tachyons};
`

const Badge = styled.img`
  display: inline-block;
  height: 50px;
  width: auto;
  ${tachyons};
`

const AndroidButton = (props) => (
  <BadgeBase href="https://app.adjust.com/v608a15" {...props}>
    <Badge
      src={AndroidImage}
      onClick={() => {
        props.track(eventConstants.appRedirectModal.redirectBtnClicked, {
          [eventConstants.appRedirectModal.btnTypeProp]: eventConstants.common.appType.android
        })
      }}
      alt="Download Unroll.Me for Android"
    />
  </BadgeBase>
)

const IosButton = ({ location, ...props }) => (
  <BadgeBase href="https://app.adjust.com/halcdd8" {...props}>
    <Badge
      src={IosImage}
      onClick={() => {
        props.track(eventConstants.appRedirectModal.redirectBtnClicked, {
          [eventConstants.appRedirectModal.btnTypeProp]: eventConstants.common.appType.iOS
        })
      }}
      alt="Download Unroll.Me for iPhone"
    />
  </BadgeBase>
)

export {
  AndroidButton,
  IosButton
}
