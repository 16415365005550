import React from 'react'
import Isvg from 'react-inlinesvg'
import LazyLoad from 'react-lazyload'
import styled, { css } from 'styled-components'
import Type from '../Type'

const FeatureWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 500px) {
    display: flex;
    margin-left: -10%;
  }

  ${props =>
    props.rtl &&
    css`
      @media only screen and (min-width: 500px) {
        flex-direction: row-reverse;
      }
    `};
`
const FeatureContent = styled.div`
  @media only screen and (min-width: 500px) {
    display: flex;
    margin-left: 10%;
    align-items: center;
    justify-content: center;
  }
`
const FeatureAside = styled.div`
  /* display: none;
  line-height: 0; */
  display: block;
  width: 60%;
  margin: 3rem auto 0 auto;

  @media only screen and (min-width: 500px) {
    margin: 0 0 0 10%;
  }
`
const Image = styled(Isvg)`
  svg {
    width: 200px;
    @media only screen and (min-width: 800px) {
      width: 240px;
    }
    @media only screen and (min-width: 1200px) {
      width: 300px;
    }
  }
`

const Feature = props => (
  <FeatureWrapper {...props}>
    <FeatureContent>
      <div>
        {props.title ? (
          <Type variant="h2" f3 lh_copy fw6 dark_gray mt0>
            {props.title}
          </Type>
        ) : null}
        {props.content ? (
          <Type variant="p" lh_copy gray>
            {props.content}
          </Type>
        ) : null}
        {!props.customAside ? <div>{props.children}</div> : null}
      </div>
    </FeatureContent>
    <FeatureAside>
      {props.customAside ? (
        <div>{props.children}</div>
      ) : (
        <LazyLoad height={300} offset={100} once>
          <Image src={props.image} alt={props.title} />
        </LazyLoad>
      )}
    </FeatureAside>
  </FeatureWrapper>
)

export default Feature
