import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'
import Box from '../Box'
import Modal from '../Modal'
import Type from '../Type'
import Logo from './Logo'
import { IosButton, AndroidButton } from './AppButtons'
import { color } from '../../global/constants'

export const messageDescriptors = defineMessages({
  welcomeP1: {
    id: 'components.modalAppRedirect.welcomeP1',
    defaultMessage: 'Welcome to Unroll',
  },
  welcomeP2: {
    id: 'components.modalAppRedirect.welcomeP2',
    defaultMessage: `Me`,
  },
  manage: {
    id: 'components.modalAppRedirect.manage',
    defaultMessage: `Manage subscriptions and clean up your inbox more easily than ever before.`,
  },
  download: {
    id: 'components.modalAppRedirect.download',
    defaultMessage: `Download for iOS & Android`
  }
})

const RedirectModal = styled(Modal)`
  background-color: #393333;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  min-height: 600px;
  height: 100vh;
  left: 0;
  margin-top: 0;
  padding: 0;
  top: 0;
  position: absolute;
  overflow-x: hidden;
`

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  height: 55%;
  width: 100%;
  position: absolute;
  margin-top: 0;
`

const ColoredDot = styled.span`
  text-rendering: optimizeLegibility;
  color: ${color.blue}
`

const BottomBox = styled(Box)`
  background-color: #ffffff !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px 25px 0 0;
  top: 60%;
  height: 45%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
`

const ModalAppRedirect = props => {
  const { intl, track } = props
  const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
    messages[key] = intl.formatMessage(messageDescriptors[key])
    return messages
  }, {})

  return (
    <RedirectModal
      isActive={props.isActive}
      closeModal={props.closeModal}
    >
      <ModalBox>
        <Box h_25></Box>
        <Box h_50>
          <Logo h_50 mb0 />
          <Type variant="P" b f3 tc>
            <Type variant="Span" near_white>{messages.welcomeP1}</Type>
            <ColoredDot>.</ColoredDot>
            <Type variant="Span" near_white>{messages.welcomeP2}</Type>
          </Type>
          <Type variant="P"  tc db lh_copy f6 fw1 moon_gray>
            {messages.manage}
          </Type>
        </Box>
        <Box h_25></Box>
      </ModalBox>
      <BottomBox>
        <Box h_75>
          <Type variant="P" f6 b center tc mt2 measure_narrow>
            {messages.download}
          </Type>
          <IosButton center tc db pl2 pr2 mt2 track={track} />
          <AndroidButton center tc db pl2 pr2 mt2 mb4 track={track} />
        </Box>
        <Box h_25></Box>
      </BottomBox>
    </RedirectModal>
  )
}

ModalAppRedirect.propTypes = {
  isActive: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default injectIntl(ModalAppRedirect)
