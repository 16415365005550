import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'
import { clearFix } from 'polished'

const Box = styled.div`
  ${props =>
    props.clearfix &&
    css`
      ${clearFix()}
    `}
  ${tachyons};
`

export default Box
