import React, { Component } from 'react'
import axios from 'axios'
import tachyons from 'styled-components-tachyons'
import YouTubePlayer from 'react-player/youtube'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import Modal from '../components/Modal'
import Button from '../components/Button'
import { IosButton, AndroidButton } from '../components/MobileStoreButtons'
import Type from '../components/Type'
import Icon from '../components/Icon'
import Feature from '../components/Feature'
import Box from '../components/Box'
import ModalDataHandling from '../components/ModalDataHandling'
import ModalAppRedirect from '../components/ModalAppRedirect'
import StyledImage from '../components/StyledImage'
import { icon } from '../global/constants'
import { Combine, Rollup, Unsubscribe, NielsenLogo, Ios } from '../components/Images'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'
import { track as trackEvent } from '../global/analytics'
import { color } from '../global/constants'
import eventConstants  from '../global/eventConstants'
import PlayBtnSvg from '../../static/assets/img/play-button.svg'
import SubscriptionsImage from '../../static/assets/img/subscription-page.png'
import SubscriptionsMobileImage from '../../static/assets/img/subscription-page-mobile.png'
import IpadIphoneImage from '../../static/assets/img/ipad-iphone.png'
import IpadIphoneMobileImage from '../../static/assets/img/ipad-iphone-mobile.png'
import IosDownloadButton from '../../static/assets/img/ios-download-button.png'
import AndroidDownloadButton from '../../static/assets/img/android-download-button.png'
import GmailLogo from '../../static/assets/img/gmail-splash.png'
import YahooLogo from '../../static/assets/img/yahoo-splash.png'
import OutlookLogo from '../../static/assets/img/outlook-splash.png'
import AolLogo from '../../static/assets/img/aol-splash.png'
import IcloudLogo from '../../static/assets/img/icloud-splash.png'
import * as cookies from "browser-cookies";

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.index.title',
    defaultMessage: 'Unsubscribe from emails, instantly',
  },
  headingP1: {
    id: 'pages.index.headingP1',
    defaultMessage: 'A',
  },
  headingP2: {
    id: 'pages.index.headingP2',
    defaultMessage: 'cleaner inbox',
  },
  headingP3: {
    id: 'pages.index.headingP3',
    defaultMessage: 'is just a click away!',
  },
  subHeadingP1: {
    id: 'pages.index.subHeadingP1',
    defaultMessage:
      'Unroll',
  },
  subHeadingP2: {
    id: 'pages.index.subHeadingP2',
    defaultMessage:
      'Me gives you the tools you',
  },
  subHeadingP3: {
    id: 'pages.index.subHeadingP3',
    defaultMessage:
      'need to manage a cluttered mailbox',
  },
  subHeadingP4: {
    id: 'pages.index.subHeadingP4',
    defaultMessage:
      'full of pesky subscriptions emails',
  },
  appButtonText: {
    id: 'pages.index.appButtonText',
    defaultMessage: 'Signup online',
  },
  appButtonABText: {
    id: 'pages.index.appButtonABText',
    defaultMessage: 'Sign Up',
  },
  nonAppButtonText: {
    id: 'pages.index.nonAppButtonText',
    defaultMessage: 'Get Started Now',
  },
  weDontChargeText: {
    id: 'pages.index.weDontChargeText',
    defaultMessage: "Why we don't charge you for this amazing service.",
  },
  section1Title: {
    id: 'pages.index.section1Title',
    defaultMessage: 'Unsubscribe with one click',
  },
  section1Content: {
    id: 'pages.index.section1Content',
    defaultMessage:
      'Unroll.Me will show you a list of all of your subscription emails, so you can instantly unsubscribe from what you don’t want.',
  },
  section2Title: {
    id: 'pages.index.section2Title',
    defaultMessage: 'Combine your favorite subscriptions into one email',
  },
  section2Content: {
    id: 'pages.index.section2Content',
    defaultMessage:
      'After you finish unsubscribing from unwanted emails, combine your favorite email subscriptions into a beautiful digest called ‘The Rollup’.',
  },
  section3Title: {
    id: 'pages.index.section3Title',
    defaultMessage: 'Live a more organized and efficient life',
  },
  section3Content: {
    id: 'pages.index.section3Content',
    defaultMessage:
      'Use The Rollup to easily access the email subscriptions that are most important to you, instead of scrolling through your inbox.',
  },
  section4Title: {
    id: 'pages.index.section4Title',
    defaultMessage: 'Our revolutionary email experience, on your phone.',
  },
  section4Content: {
    id: 'pages.index.section4Content',
    defaultMessage:
      'Streamlining your inbox has never been easier. Unsubscribe from unwanted emails and consolidate the emails you want to keep — all with one swipe.',
  },
  getStarted: {
    id: 'pages.index.getStarted',
    defaultMessage: 'Get started',
  },
  learnMore: {
    id: 'pages.index.learnMore',
    defaultMessage: 'Learn more',
  },
  aboutTitle: {
    id: 'pages.index.aboutTitle',
    defaultMessage: 'About our company',
  },
  aboutContent: {
    id: 'pages.index.aboutContent',
    defaultMessage:
      'Unroll.Me is owned by NielsenIQ, an e-commerce measurement business that provides companies with insights into industry trends, corporate performance, and the competitive landscape. When you sign up for Unroll.Me, you can become part of NielsenIQ’s online shopping panel.',
  },
})

const hostApiMap = {
  'dev-newunroll.unroll.me': 'apiv2-staging.unroll.me',
  'staging.unroll.me': 'apiv2-staging.unroll.me',
  'unroll.me': 'apiv2.unroll.me'
}

const SignupButton = styled(Button)`
  width: 155px;
`

const DarkGradient = styled.span`
  background: #000000;
  background: linear-gradient(to bottom, #404040 60%, #FFFFFF 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const BlueGradient = styled.span`
  background: #000000;
  background: linear-gradient(to bottom, #00bcbb 50%, #FFFFFF 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Player = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  max-width: 600px;
  min-width: 270px;

  @media screen and (min-device-width: 600px) {
    min-width: 500px;
  }

  @media screen and (min-device-width: 1000px) {
    min-width: 640px;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const track = (eventName, eventProps) => {
  trackEvent(eventName, {
    ...eventProps,
    Page: eventConstants.home.pageName
  })
}

const getSignupLink = (isIphone, isAndroid) => {
  if (isIphone) {
    return 'https://bnc.lt/IPig/83sUGgL57M'
  }
  if (isAndroid) {
    return 'https://bnc.lt/IPig/p0NveowSjH'
  }

  return typeof window !== 'undefined'
    ? `/a/signup${window.location.search}`
    : '';
}

class IndexPage extends Component {
  state = {
    isDataModalOpen: false,
    isAppRedirectModalOpen: false,
    isVideoModalOpen: false,
    isIphone: false,
    isAndroid: false,
    isDesktop: false
  }

  componentDidMount() {
    const navigatorAgent = navigator.userAgent
    const isIphone = /iPhone OS/.test(navigatorAgent)
    const isAndroid = /Android/.test(navigatorAgent)
    const isDesktop = !isAndroid && !isIphone;

    const accCount = cookies.get("accCount")
    console.log("accCount", accCount)
    if(accCount){
      const count = +window.atob(accCount) || 0
      if(count>0){
        window.location="/app/inbox"
      }
    }
    this.setState({
      isIphone,
      isAndroid,
      isDesktop
    })

    track(eventConstants.home.pageLoad)
  }

  handleDataModal(callToAction) {
    if (!this.state.isDataModalOpen) {
      track(eventConstants.home.dataModalClicked, {
        [eventConstants.common.ctaProp]: callToAction
      })
    }
    this.setState({ isDataModalOpen: !this.state.isDataModalOpen })
  }

  handleAppRedirectModal() {
    if (this.state.isAppRedirectModalOpen) {
      document.body.style.overflow = 'unset'
    } else {
      document.body.style.overflow = 'hidden'
    }
    this.setState({ isAppRedirectModalOpen: !this.state.isAppRedirectModalOpen })
  }

  handleVideoModal() {
    if (!this.state.isVideoModalOpen) {
      track(eventConstants.app.videoLinkClicked)
    }
    this.setState({ isVideoModalOpen: !this.state.isVideoModalOpen })
  }

  render() {
    const { intl } = this.props
    const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
      messages[key] = intl.formatMessage(messageDescriptors[key])
      return messages
    }, {})

    const { isAndroid, isIphone, isDesktop, isDataModalOpen, isAppRedirectModalOpen } = this.state
    const signUpLink = getSignupLink(isIphone, isAndroid)

    return (
      <Layout title={messages.title}>
        <Hero>
          <Section bgColor={color.grayDarkPage}>
            <Type variant="div" ph3_ns ph2 fl_l pt2 pt4_ns mh1_ns>
              <Type variant="div" flex flex_column>
                {/* Heading */}
                <Type variant="div" mh4_ns fontSize={[40, 50, 72]}>
                  <Type variant="div" flex white mt2 lh_title>
                    <Type variant="div" b mb0 white lh_solid>
                      {messages.headingP1}&nbsp;
                    </Type>
                    <Type variant="div" textColor={color.blueUnroll} b mb0 lh_solid>
                      {messages.headingP2}
                    </Type>
                  </Type>
                  <Type variant="div" mt1 fl white b lh_title>
                    {messages.headingP3}
                  </Type>
                </Type>
                {/* Sub-Heading */}
                <Type variant="div" flex flex_column mh4_ns mt2 white lh_copy fontSize={[21, 26, 32]}>
                  <Type variant="div" tl lh_copy  mv0 white fw4>
                    {messages.subHeadingP1}
                    <Type variant="span" textColor={color.blueUnroll}>.</Type>
                    <Type variant="span">{messages.subHeadingP2}</Type>
                  </Type>
                  <Type variant="div" tl lh_copy mv0 white fw4>
                    {messages.subHeadingP3}
                  </Type>
                  <Type variant="div" tl lh_copy mt0 white fw4>
                    {messages.subHeadingP4}.
                  </Type>
                </Type>
              </Type>
              {/* Buttons */}
              <Type variant="div" flex flex_column flex_row_ns mh4_ns center mt3 pt1_l >
                <Type variant="div" fl_ns>
                  <Button
                    href={signUpLink}
                    alt="Signup"
                    w_100
                    signup
                    soft
                    f5_ns
                  >
                    Sign Up For Free
                  </Button>
                </Type>
                <Type variant="div" ml2_ns mt2 mt0_ns>
                  <Button
                    href='#'
                    onClick={this.handleVideoModal.bind(this)}
                    alt="Video"
                    w_100
                    video
                    soft
                    f5_ns
                  >
                    <StyledImage src={PlayBtnSvg} width="10" height="12" pr1 />
                    Watch The Video
                  </Button>
                </Type>
              </Type>
              {/* Subscriptions Image */}
              <Type variant="div" mv4 pv0 pv3_ns mh4_ns mt3>
                <StyledImage src={SubscriptionsImage} w_100 center dn db_l db_m />
                <StyledImage src={SubscriptionsMobileImage} dn_ns />
              </Type>
            </Type>
          </Section>
        </Hero>

        <Section bg_white w_100 pb4>
          <Type variant="div" ph1 tc full fl_l pt3>
            <Type variant="div" flex flex_column w_100>
              <Type variant="h2" tc mh1 fontSize={[16, 16, 28]}>
                Easy Email Cleanup
              </Type>
              <Type variant="div" tc mh1 b fontSize={[29, 29, 64]}>
                <Type variant="span" textColor={color.blueUnroll}>Keep</Type>
                <Type variant="span">,&nbsp;</Type>
                <Type variant="span" textColor={color.redDark}>Unsubscribe</Type>
                <Type variant="span">,&nbsp;</Type>
                <Type variant="span" textColor={color.orange}>Rollup</Type>
              </Type>
              <Type variant="div" w_60_l ph1 center lh_copy mt2 mb3 fontSize={[14, 14, 25]}>
                Decluttering your inbox has never been so easy, or looked so good. With the new Unroll.Me, you can unsubscribe from unwanted emails, keep the ones you want, and rollup the rest into a single daily digest.
              </Type>
            </Type>
            <Type variant="div" mt1 mt3_ns ml3_l>
              <StyledImage src={IpadIphoneImage} w_60_l center dn db_l />
              <StyledImage src={IpadIphoneMobileImage} dn_l />
            </Type>
            <Type variant="div" mt1 mt3_ns mb3_ns flex_ns>
              {/* Ios Button */}
              { (isIphone || isDesktop) &&
                <Type variant="div" center w_50_ns>
                <Button
                  href="https://bnc.lt/IPig/83sUGgL57M"
                  link
                  fr_ns
                  onClick={() => {
                    track(eventConstants.common.visitedAppStore, {
                      [eventConstants.common.appTypeProp]: eventConstants.common.appType.iOS
                    })
                  }}
                >
                  <StyledImage src={IosDownloadButton} width={[328, 300, 300, 300]} height={[50]} mt2 />
                </Button>
              </Type>
              }
              {/* Android Button */}
              {(isAndroid || isDesktop) &&
                  <Type variant="div" center w_50_ns>
                    <Button
                        href="https://bnc.lt/IPig/p0NveowSjH"
                        link
                        fl_ns
                        onClick={() => {
                          track(eventConstants.common.visitedAppStore, {
                            [eventConstants.common.appTypeProp]: eventConstants.common.appType.android
                          })
                        }}
                    >
                      <StyledImage src={AndroidDownloadButton} width={[328, 300, 300, 300]} height={[50]} mt2/>
                    </Button>
                  </Type>
              }
            </Type>
          </Type>
        </Section>

        <Section bgColor={color.blueUnroll} w_100 pt2 pt3_ns pb4>
          <Type variant="div" tc ph2 center>
            <Type variant="h2" white f2_l f5 tc mh1 mb4_l mb3 fontSize={[16, 16, 28]}>
              Unroll.Me Supports Major Email Providers
            </Type>
            <Type variant="div" tc flex justify_center mh4 ph4>
              <StyledImage src={GmailLogo} mh2 mh3_l ph1_l width={[38, 38, 105]} height={[29, 29, 70]} />
              <StyledImage src={OutlookLogo} mh2 mh3_l ph1_l width={[29, 29, 79]} height={[29, 29, 70]} />
              <StyledImage src={YahooLogo} mh2 mh3_l ph1_l width={[33, 33, 91]} height={[29, 29, 70]} />
              <StyledImage src={IcloudLogo} mh2 mh3_l ph1_l width={[45, 45, 125]} height={[29, 29, 70]} />
              <StyledImage src={AolLogo} mh2 mh3_l ph1_l width={[38, 38, 104]} height={[28, 28, 70]} />
            </Type>
          </Type>
        </Section>

        <Section bgColor={color.white} w_100>
          <Type variant="div" pt2 black tc b fontSize={[70, 100, 185]}>
            <DarkGradient>
              <Type variant="span">Unroll</Type>

              <BlueGradient>
                <Type variant="span">.</Type>
              </BlueGradient>

              <Type variant="span">me</Type>
            </DarkGradient>
          </Type>

          <Type variant="div" tc b pb3>
            <Type variant="span" textColor={color.blueUnroll} fontSize={[18, 21, 40]}>
              Your inbox awaits…
            </Type>
          </Type>
        </Section>

        {isDataModalOpen ? (
          <ModalDataHandling
            isActive={isDataModalOpen}
            track={track}
            closeModal={this.handleDataModal.bind(this)}
          />
        ) : null}

        {isAppRedirectModalOpen ? (
          <ModalAppRedirect
            isActive={isAppRedirectModalOpen}
            track={track}
            closeModal={this.handleAppRedirectModal.bind(this)}
          />
        ) : null}

        <Modal
          isActive={this.state.isVideoModalOpen}
          closeModal={this.handleVideoModal.bind(this)}
          maxWidth="680px"
        >
          <Player>
            <YouTubePlayer
              url="https://www.youtube.com/embed/z9BLdfjHphs"
              width="100%"
              height="100%"
              className="react-player"
              playing
              config={{
                youtube: {
                  playerVars: {
                    origin: typeof window !== 'undefined' ? window.location.origin : ''
                  }
                }
              }}
            />
          </Player>
        </Modal>
      </Layout>
    )
  }
}

export default injectIntl(IndexPage)
