import React from 'react'
import { BadgeBase, Badge } from './shared'
import AndroidImage from './android.svg'
import eventConstants from '../../global/eventConstants'

const AndroidButton = (props) => (
  <BadgeBase href="https://bnc.lt/IPig/p0NveowSjH" {...props}>
    <Badge
      src={AndroidImage}
      onClick={() => {
        props.track(eventConstants.common.visitedAppStore, {
          [eventConstants.common.appTypeProp]: eventConstants.common.appType.android
        })
      }}
      alt="Download Unroll.Me for Android"
    />
  </BadgeBase>
)

export default AndroidButton
