import React from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import LogoHorizontal from './LogoHorizontal.svg'

const Svg = styled.img`
  width: 80px;
  margin: auto;
  display: block;
  ${tachyons};

  @media (min-width: 600px) {
    width: 130px;
  }
`

const Logo = props => (
  <Svg
    src={LogoHorizontal}
    {...props}
    alt="Unroll.Me"
  />
)

export default Logo
