import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'

const BadgeBase = styled.a`
  display: inline-block;

  ${tachyons};
`

const Badge = styled.img`
  display: inline-block;
  height: 46px;
  width: auto;
  ${tachyons};
`

export { BadgeBase, Badge }
