import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Modal from '../Modal'
import Type from '../Type'
import { Data } from '../Images'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'

export const messageDescriptors = defineMessages({
  title: {
    id: 'components.modalDataHandling.title',
    defaultMessage: 'What We Do With Your Data',
  },
  text1: {
    id: 'components.modalDataHandling.text1',
    defaultMessage: `As a means of keeping our service free, NielsenIQ, which owns
      Unroll.Me, measures e-commerce through a technology that automatically
      recognizes commercial emails and extracts purchase information from them.
      Our technology is designed to ignore personal email, which does not help
      us measure market trends.`,
  },
  text2: {
    id: 'components.modalDataHandling.text2',
    defaultMessage: `NielsenIQ strips away personal information such as your name
      and address, to build measurement products that provide insights into
      consumer trends.`,
  },
  text5: {
    id: 'components.modalDataHandling.text5',
    defaultMessage: 'Learn more about how we use data.',
  },
})

const ModalDataHandling = props => {
  const { intl } = props
  const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
    messages[key] = intl.formatMessage(messageDescriptors[key])
    return messages
  }, {})
  return (
    <Modal
      title={messages.title}
      isActive={props.isActive}
      closeModal={props.closeModal}
    >
      <img src={Data} alt="How we process data" />
      <Type variant="p" lh_copy gray>
        {messageDescriptors.text1.defaultMessage}
      </Type>
      <Type variant="p" lh_copy gray>
        {messageDescriptors.text2.defaultMessage}
      </Type>
      <Type variant="p" lh_copy gray>
        <Link
          to="/your-data"
        >
          {messages.text5}
        </Link>
      </Type>
    </Modal>
  )
}

ModalDataHandling.propTypes = {
  isActive: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default injectIntl(ModalDataHandling)
